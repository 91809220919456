<template>
  <div class="wrappick">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="5" :sm="24" v-if="true">
            <a-form-item label="仓库">
              <a-select
                show-search
                allow-clear
                v-model="queryParam.warehouse_id"
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                placeholder="请输入仓库名称"
                @search="this.warehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="订单号">
              <a-input
                v-model="queryParam.order_no"
                placeholder="请输入订单号"
                @keyup.enter.native="$refs.table.refresh(true)"
                allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="拣货单号">
              <a-input
                v-model="queryParam.pick_list_no"
                placeholder="请输入拣货单号"
                @keyup.enter.native="$refs.table.refresh(true)"
                allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24" v-if="false">
            <a-form-item label="拣货人">
              <a-select
                v-model="queryParam.pick_by_name"
                placeholder="请选择"
                allow-clear
                @search="get_users"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption">
                <a-select-option
                  :value="val.nickname"
                  v-for="(val, key) of this.userList"
                  :key="key">{{ val.nickname }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24" v-if="false">
            <a-form-item label="打包人">
              <a-select
                v-model="queryParam.pack_by_name"
                placeholder="输入关键词选择"
                allow-clear
                @search="get_users"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option
                  :value="val.nickname"
                  v-for="(val, key) of this.userList"
                  :key="key">{{ val.nickname }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" allow-clear>
                <a-select-option
                  :value="key"
                  v-for="(val, key) of this.$Dictionaries.pick_status"
                  :key="key">{{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="search()">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator" v-if="true">
      <a-button @click="selectExpress" type="primary">
        指定物流
      </a-button>
      <a-button @click="showPickerModal" v-if="false">
        拣货人
      </a-button>
      <a-button @click="showPackerModal" v-if="false">
        打包人
      </a-button>
      <a-button @click="showPrint" v-if="false">
        打印
      </a-button>
    </div>
    <a-alert :showIcon="false" style="margin-bottom: 16px" type="error">
      <template slot="message">
        <span style="margin-right: 12px">已选择: <a style="font-weight: 600">{{ this.selectedRowKeys.length }}</a></span>
        <a style="margin-left: 24px" @click="clear" :disabled="!hasSelected" v-if="hasSelected">清空</a>
      </template>
    </a-alert>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :row-selection="rowSelection"
      :loading="loading"
      :columns="columns"
      :scroll="{ x: 2500 }"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
            v-if="record.status !== 5 && (record.waybill_no == '' || record.waybill_no == undefined)"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" v-if="(record.status !== 5) && (record.waybill_no == '' || record.waybill_no == undefined)"/>
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="toCommodities(record)">商品列表</a>
              </a-menu-item>
              <a-menu-item v-if="record.goods_type === 2">
                <a @click="handleDeclaration(record)">申报推送</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </span>
    </s-table>
    <select-pick-pack-form
      ref="selectPickPackModal"
      v-if="selectDoublevisible"
      :visible.sync="selectDoublevisible"
      :loading="confirmPickPackLoading"
      :model="mdl"
      @cancel="cancel_users"
      @ok="select_users"
    />
    <declaration-status-form
      v-if="declarationStatusVisible"
      :visible.sync="declarationStatusVisible"
      :model="declarationStatusInfo"
      @cancel="cancelDeclarationStatus"
    />
    <select-express-form
      ref="selectExpressModal"
      v-if="select_express_visible"
      :visible.sync="select_express_visible"
      :loading="confirmExpressLoading"
      :model="mdl"
      @cancel="cancelExpress"
      @ok="selectExpressOK"
    />
    <!-- 下面的这些暂时不用 start -->
    <a-modal
      @cancel="handleCancel"
      v-model="selectvisible"
      title="请选择"
      ok-text="确认"
      cancel-text="取消"
      @ok="select_user">
      <a-select
        style="width: 280px"
        @change="handleSelectChange"
        v-model="user_id"
        @search="get_users"
        show-search
        option-filter-prop="children"
        :filter-option="filterOption"
        allowClear
      >
        <a-select-option :value="item.id" v-for="(item, index) in userList" :key="index">
          {{ item.nickname }}
        </a-select-option>
      </a-select>
    </a-modal>
    <a-modal
      style="width: 300px"
      v-model="select_status_visible"
      title="请选择状态"
      ok-text="确认"
      cancel-text="取消"
      @ok="select_status"
    >
      <a-select style="width: 280px" @change="handleSelectChange" v-model="select_status_data">
        <a-select-option
          v-for="(val, key) in this.$Dictionaries.pick_status"
          :key="key"
          :value="key"
          :disabled="select_status_data>=key">
          {{ val }}
        </a-select-option>
      </a-select>
      <br />
      <br />
      <span v-if="select_status_data === 4">
        打包重量：<a-input-number :min="0" v-model="weight" style="width: 180px"/> 千克</span>
    </a-modal>
    <a-modal v-model="way_bill_visible" title="运单详情" @ok="okwaybill" :footer="null">
      <p style="font-size: 16px;color: #333;font-weight: 500;">运单号：{{ way_bill_data.bill_no }}</p>
      <timeline :timelineList="way_bill_data.trace_list"></timeline>
    </a-modal>
    <!--startprint-->
    <div id="express">
      <div class="printBox" v-for="item in printList" :key="item.pick_list_id" style="page-break-after:always;">
        <div class="printCompany">
          <span class="companyLogo">
            <img src="~@/assets/shentong.svg" height="">
          </span>

          <span class="companyTip">快递包裹</span>
        </div>
        <div class="waybillNum">
          <jsbarcode-tem :code="item.waybill_no"></jsbarcode-tem>
        </div>
        <div class="trackNum">
          {{ item.big_word }}
        </div>
        <div class="packGround">
          <span class="packAd"><span class="ji">集</span>{{ item.package_place }}</span>
          <!--          <span class="printTime"></span>
&lt;!&ndash;          <span class="printTime">{{ new Date().toLocaleDateString() }}</span>&ndash;&gt;-->
        </div>
        <div class="sendReceiveAdBox">
          <div class="sendReceiveAdInfo">
            <div class="receiveInfoBox">
              <span class="receive">收件</span>
              <span class="receiveContent">
                {{ item.receiving_address }}
                <br/>
                {{ item.receiving_name }}  {{ item.receiving_phone }}

              </span>
            </div>
            <div class="sendInfoBox">
              <span class="send">寄件</span>
              <span class="sendContent">
                {{ item.send_address }}<br>
                {{ item.send_name }} {{ item.send_mobile }}
              </span>
            </div>
          </div>
        </div>
        <div class="waybillNum2">
          <jsbarcode-tem :code="item.waybill_no"></jsbarcode-tem>
        </div>
        <div class="customArea">
          <!--          托寄物:<br/>-->
          {{ item.product }}
        </div>
        <div class="singerName">
          <p><span style="float: right">已验视</span></p>
        </div>
      </div>
    </div>
    <!--b-->
    <div id="expressYuanTong">
      <div class="printBoxYuanTong" v-for="item in printList" :key="item.pick_list_id" style="page-break-after:always;">
        <div class="printCompanyYuanTong">
          <div class="companyLogoYuanTong" style="visibility:hidden;">
            <img src="~@/assets/yuantong.svg">
            <span>{{ item.waybill_no }}</span>
            <span>{{ currentTime }}</span>
          </div>
          <div class="companyLogoRight" v-if="false">
            <div style="display: flex;justify-content: space-between">
              <img src="~@/assets/huodaofukuan.svg" style="width: 9mm; height: 9mm; ">
              <img src="~@/assets/daofu.svg" style="width: 9mm; height: 9mm; margin-left: 1mm; margin-right: 1mm ">
              <img src="~@/assets/guojisanjian.svg" style="width: 9mm; height: 9mm; ">
            </div>
            <span>代收货款 ￥10000</span>
          </div>
        </div>
        <div class="waybillNumYuanTong">
          <jsbarcode-tem
            :code="item.waybill_no"
            :width="yuantong_barcode_width"
            :height="yuantong_barcode_height"
            :font-size="fontSize"
          ></jsbarcode-tem>
        </div>
        <div class="trackNumYuanTong">
          {{ item.big_word }}
        </div>
        <div class="sendReceiveAdInfoYuanTong">
          <div class="receiveInfoBoxYuanTong">
            <span class="receiveYuanTong">收</span>
            <span class="receiveContentYuanTong">
              <span>{{ item.receiving_name }}  {{ item.receiving_phone }}</span>
              <br/>
              <span>{{ item.receiving_address }}</span>
            </span>
          </div>
          <div class="sendInfoBoxYuanTong">
            <span class="sendYuanTong">寄</span>
            <span class="sendContentYuanTong">
              <span>{{ item.send_name }} {{ item.send_mobile }}</span>
              <br/>
              <span>{{ item.send_address }}</span>
            </span>
          </div>
        </div>
        <div class="waybillNumParentYuanTong">
          <span class="waybillNumLeft">{{ item.big_word }}</span>
          <div class="waybillNumYuanTong2">
            <jsbarcode-tem
              :code="item.waybill_no"
              :width="yuantong_barcode_width2"
              :height="yuantong_barcode_height2"
              :fontSize="fontSize2" />
          </div>
        </div>
        <div class="jijianYuanTong">
          {{ item.product }}
        </div>
      </div>
    </div>
    <!-- 下面的这些暂时不用 end -->
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import Timeline from './timeline'
import { Dictionaries } from '@/common/AllConstant'
import {
  picks_delete,
  picks_list, waybill_info,
  wms_picks_edit, wms_picks_declaration_status,
  wms_picks_packer, pick_list_express,
  wms_picks_patch,
  wms_picks_picker
} from '@/api/c_wms_picks'
import { user_list } from '@/api/user'
import jsbarcodeTem from '@/views/c-wms/pick/table/jsbarcodeTem'
import YuanTongPrint from '@/views/c-wms/pick/table/YuanTongPrint'
import * as printJS from 'print-js'
import SelectPickPackForm from '@/views/c-wms/pick/modules/SelectPickPackForm'
import DeclarationStatusForm from '@/views/c-wms/pick/modules/DeclarationStatusForm'
import SelectExpressForm from '@/views/c-wms/pick/modules/SelectExpressForm'
import debounce from 'lodash/debounce'
import { warehouse_list } from '@/api/c_wms_warehouse'

export default {
  name: 'TableList',
  components: {
    STable,
    Timeline,
    jsbarcodeTem,
    YuanTongPrint,
    SelectPickPackForm,
    DeclarationStatusForm,
    SelectExpressForm
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    return {
      way_bill_data: {},
      selectRecord: {},
      way_bill_visible: false,
      printList: [],
      userList: [],
      inventory_cancel_visible: false,
      select_status_visible: false,
      selectvisible: false,
      select_express_visible: false,
      selectDoublevisible: false,
      confirmPickPackLoading: false,
      confirmExpressLoading: false,
      select_edit_record: {},
      picker_packer: 0,
      weight: 0,
      key: 1,
      key2: 2,
      yuantong_barcode_width: 2.6,
      yuantong_barcode_width2: 1.9,
      yuantong_barcode_height: 32,
      yuantong_barcode_height2: 28,
      fontSize: '18px',
      fontSize2: '10px',
      loading: false,
      reason: undefined,
      user_id: '请选择',
      select_picker_id: '请选择',
      select_status_data: '请选择',
      select_packer_id: '请选择',
      confirmLoading: false,
      // 创建窗口控制
      current_bill_id: 0,
      visible: false,
      declarationStatusVisible: false,
      mdl: {},
      declarationStatusInfo: {},
      dataSource_wms_warehouse: [],
      warehouse_fetching: false,
      dictionaries: Dictionaries,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          fixed: 'left',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '批次',
          ellipsis: true,
          width: 100,
          fixed: 'left',
          dataIndex: 'batch'
        },
        {
          title: '仓库',
          width: 200,
          dataIndex: 'warehouse',
          ellipsis: true
        },
        {
          title: '拣货单号',
          ellipsis: true,
          width: 250,
          dataIndex: 'pick_list_no'
        },
        {
          title: '订单号',
          ellipsis: true,
          width: 250,
          dataIndex: 'order_no'
        },
        {
          title: '运单号',
          ellipsis: true,
          width: 250,
          dataIndex: 'waybill_no'
        },
        {
          title: '拣货人',
          dataIndex: 'pick_by',
          width: 120,
          ellipsis: true
        },
        {
          title: '打包人',
          dataIndex: 'pack_by',
          width: 120,
          ellipsis: true
        },
        {
          title: '毛重',
          dataIndex: 'weight',
          width: 120,
          ellipsis: true
        },
        {
          title: '货物类型',
          align: 'center',
          width: 100,
          dataIndex: 'goods_type',
          customRender: (text) => this.$Dictionaries.goods_type[text]
        },
        {
          title: '订单来源',
          align: 'center',
          width: 100,
          dataIndex: 'source',
          customRender: (text) => this.$Dictionaries.pick_list_order_source[text]
        },
        {
          title: '配送方式',
          align: 'center',
          width: 100,
          dataIndex: 'distribution',
          customRender: (text) => this.$Dictionaries.delivery_method[text]
        },
        {
          title: '物流',
          align: 'center',
          width: 100,
          dataIndex: 'express',
          customRender: (text) => text === -1 ? '测试' : this.$Dictionaries.logistics_company_index[text]
        },
        // {
        //   title: '申报状态',
        //   align: 'center',
        //   width: 100,
        //   dataIndex: 'declaration_status',
        //   customRender: (text) => this.$Dictionaries.declaration_status[text]
        // },
        {
          title: '状态',
          align: 'center',
          width: 150,
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.pick_status[text]
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          width: 150,
          align: 'center',
          ellipsis: true
        },
        {
          title: '完成时间',
          align: 'center',
          dataIndex: 'done_time',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text,
          width: 150
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return picks_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          getCheckboxProps: this.getCheckboxProps,
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.get_users('')
    this.handleWarehouseSearch(undefined)
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    },
    currentTime () {
      return moment().format('YYYY-MM-DD HH:mm')
    },
    rowSelection () {
      const that = this
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          that.onSelectChange(selectedRowKeys, selectedRows)
        },
        selectedRowKeys: this.selectedRowKeys,
        getCheckboxProps: record => ({
          props: {
            disabled: record.waybill_no !== ''
          }
        })
      }
    }
  },
  methods: {
    clear () {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    okwaybill () {
      this.way_bill_visible = false
    },
    showPrint () {
      var that = this
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请选择要打印的单据')
        return
      }
      waybill_info({ pick_list_ids: this.selectedRowKeys.join(',') }).then(res => {
        if (res.code === 1000) {
          that.printList = res.data
          let printable = 'expressYuanTong'
          let logistics_company = that.queryParam.logistics_company
          if (logistics_company === undefined) {
            logistics_company = that.selectedRows[0].logistics_company
          } else {
            if (that.selectedRows[0].logistics_company !== logistics_company) {
              logistics_company = that.selectedRows[0].logistics_company
            }
          }
          if (Number(logistics_company) === 1) {
            printable = 'express'
          } else if (Number(logistics_company) === 3) {
            printable = 'expressYuanTong'
            // printable = 'yuantong'
            setTimeout(function () {
              printJS({
                printable: printable,
                type: 'html',
                targetStyles: ['*'],
                font_size: '',
                style: '@page {size: 120mm 190mm; margin:0 1mm}'
              })
            }, 0)
            return
          }
          setTimeout(function () {
            printJS({ printable: printable, type: 'html', targetStyles: ['*'], font_size: '' })
          }, 0)
        }
      })
    },
    search () {
      this.$refs.table.clearSelected()
      this.$refs.table.refresh()
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    },
    get_users (value) {
      user_list({ nickname: value, role: 1 }).then(({ data }) => {
        console.log(data, '我是列表')
        this.userList = data.entries || []
      })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleCancel () {
      this.user_id = '请选择'
    },
    select_user () {
      if (this.user_id === '请选择') {
        this.$message.warning('请选择')
        return
      }
      if (this.picker_packer === 1) {
        const param = {
          pick_id_list: this.selectedRows.filter((item) => {
            return item.status !== 6
          }).map((item) => {
            return item.id
          }),
          packer_id: (this.user_id === undefined) ? -1 : this.user_id,
          order_no: this.selectedRows.filter((item) => {
            return item.status !== 6
          }).map((item) => {
            return item.order_no
          })[0],
          pick_list_no: this.selectedRows.filter((item) => {
            return item.status !== 6
          }).map((item) => {
            return item.pick_list_no
          })[0]
        }
        wms_picks_packer(param).then((res) => {
          this.selectvisible = false
          if (res.code === 1000) {
            this.$refs.table.refresh()
          }
          this.user_id = '请选择'
        })
      } else if (this.picker_packer === 2) {
        const param = {
          pick_id_list: this.selectedRows.filter((item) => {
            return item.status !== 6
          }).map((item) => {
            return item.id
          }),
          picker_id: (this.user_id === undefined) ? -1 : this.user_id,
          order_no: this.selectedRows.filter((item) => {
            return item.status !== 6
          }).map((item) => {
            return item.order_no
          })[0]
        }
        wms_picks_picker(param).then((res) => {
          this.selectvisible = false
          if (res.code === 1000) {
            this.$refs.table.refresh()
          }
          this.user_id = '请选择'
        })
      }
    },
    cancel_users () {
      this.selectDoublevisible = false
    },
    cancelExpress () {
      this.select_express_visible = false
    },
    cancelDeclarationStatus () {
      this.declarationStatusVisible = false
    },
    select_users () {
      const form = this.$refs.selectPickPackModal.form
      this.confirmPickPackLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          wms_picks_edit(values, this.select_edit_record.id).then((res) => {
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            form.resetFields()
          })
        } else {
          this.confirmPickPackLoading = false
        }
      })
    },
    selectExpressOK () {
      const form = this.$refs.selectExpressModal.form
      this.confirmExpressLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          console.log('selectExpressOK', values)
          const param = {
            pick_list_list: this.selectedRows.filter((item) => {
              return item.express === 0
            }).map((item) => {
              return item.pick_list_no
            }),
            ...values
          }
          pick_list_express(param).then((res) => {
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
            if (res.fail !== undefined && res.fail.length > 0) {
              this.$error({
                title: '错误拣货单号',
                content: res.fail.join(', ') + '' + res.msg
              })
            }
          }).finally(() => {
            form.resetFields()
            this.select_express_visible = false
            this.confirmExpressLoading = false
          })
        } else {
          this.confirmExpressLoading = false
        }
      })
    },
    select_status () {
      if (this.select_status_data === '请选择') {
        this.$message.warning('请选择')
        return
      }
      const param = {
        status: this.select_status_data
      }
      if (this.select_status_data === '4' && this.weight > 0) {
        param.weight = this.weight
      }
      wms_picks_patch(param, this.select_edit_record.id).then((res) => {
        this.select_status_visible = false
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
        this.select_status_data = '请选择'
      })
    },
    getCheckboxProps (record) {
      return {
        props: {
          disabled: record.waybill_no !== ''
        }
      }
    },
    showPackerModal () {
      if (this.selectedRows.length === 0) {
        this.$message.warning('请先选择拣货单')
        return
      }
      this.selectvisible = true
      this.picker_packer = 1
    },
    selectExpress () {
      if (this.selectedRows.length === 0) {
        this.$message.warning('请先选择拣货单')
        return
      }
      const selectedList = this.selectedRows.filter((item) => {
        return item.express === 0
      }).map((item) => {
        return item.pick_list_no
      })
      if (selectedList.length === 0) {
        this.$message.warning('拣货单已指定物流')
        return
      }
      this.select_express_visible = true
    },
    showPickerModal () {
      if (this.selectedRows.length === 0) {
        this.$message.warning('请先选择拣货单')
        return
      }
      this.selectvisible = true
      this.picker_packer = 2
    },
    hideModal () {
      this.selectvisible = false
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            getCheckboxProps: this.getCheckboxProps,
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      this.mdl = null
      this.visible = false
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      console.log(record)
      this.select_status_visible = true
      this.select_edit_record = record
      this.select_status_data = record.status.toString()
    },
    toCommodities (record) {
      this.$router.push({ path: '/zb/wms/picks/' + record.id + '/goods', query: { order_no: record.order_no } })
    },
    handleDelete (record) {
      picks_delete(record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      console.log(selectedRows, selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleDeclaration (record) {
      wms_picks_declaration_status({ order_no: record.order_no }).then(res => {
        // 刷新表格
        if (res.code === 1000) {
          const list = res.data.entries
          if (list && list.length > 0) {
            // 显示弹框
            this.declarationStatusVisible = true
            this.declarationStatusInfo = {
              ...list[0],
              waybill_no: record.waybill_no
            }
          } else {
            this.$message.warning('数据为空')
          }
        } else {
          this.$message.warning('数据为空')
        }
      })
    },
    handleWarehouseSearch (value) {
      this.dataSource_wms_warehouse = []
      this.warehouse_fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleWmsWarehouseChange (value) {
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      }
    }
  }
}
</script>
<style>
 .wrappick{
  max-height: 1000px;
  overflow: hidden;
}
#express {
  box-sizing: border-box;
}

 *{padding:0; margin:0;}

.printBox {
  position: relative;
  z-index: -1;
  width: 100mm;
  border: 1mm solid #000;
  margin-left: 15mm;
  box-sizing: border-box;
  height: 180mm;
  color: #000000;
  font-family: 'Microsoft YaHei',serif;
}
  .printBoxYuanTong {
    position: relative;
    z-index: -1;
    width: 109mm;
    height: 190mm;
    margin-left: 1mm;
    text-align: center;
    color: #000000;
    font-family: 'Microsoft YaHei',serif;
}

.printCompany {
  vertical-align: middle;
  border-bottom: 1px solid #000000;
  height: 14mm;
  line-height: 14mm;
  padding: 0 1.5mm;
}

.printCompanyYuanTong {
  border-bottom: 1px solid #000000;
  height: 20mm;
  display: flex;
  justify-content: space-between;
  padding: 0 0;
}

.companyLogo {
  width: 30mm;
  height: 14mm;
}

.companyLogoYuanTong {
  width: 42mm;
  height: 20mm;
  display: flex;
  justify-content: left;
  justify-items: center;
  flex-direction: column;
  padding: 1mm;
}

.companyLogoRight {
  width: 34mm;
  height: 20mm;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1mm;
}

.companyLogoRight > span {
  padding-top: 3mm;
  margin: 0;
  font-size: 8pt;
}

.companyLogoYuanTong > img {
  width: 26mm;
  height: 8mm;
}

.companyLogoYuanTong > span{
  padding-left: 2mm;
  margin: 0;
  font-size: 8pt;
}
/*.companyLogoYuanTong > span:last-child {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  font-size: 8pt;*/
/*}*/

.companyLogo > img {
  width: 26mm;
  height: 10mm;
  margin-top: 1.5mm;
}
.companyTip {
  float: right;
  font-size: 12pt;
  height: 8mm;
}

.trackNum {
  font-weight: bold;
  line-height: 18mm;
  height: 18mm;
  font-size: 60px;
  text-align: center;
  border-bottom: 1px solid #000000;
}

.trackNumYuanTong {
  font-weight: bold;
  line-height: 18mm;
  height: 18mm;
  width: 105mm;
  margin-right: 2mm;
  margin-left: 2mm;
  font-size: 24pt;
  text-align: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000000;
}

.packInfoBox {
  display: table;
}

.packGround {
  vertical-align: middle;
  border-bottom: 1px solid #000000;
  height: 10mm;
}
.ji {
  font-size: 16pt;
  font-weight: bold;
  width: 20mm;
  height: 20mm;
  border: 2px solid;
}
.packAd {
  font-size: 16pt;
  font-weight: bold;
  width: 74mm;
  height: 10mm;
  padding-left: 1.5mm;
  display: table-cell;
  vertical-align: middle;
}

.printTime {
  font-size: 5pt;
  display: table-cell;
  width: 24mm;
  border-left: 1px solid #000;
  vertical-align: middle;
}

.sendReceiveAdBox {
  border-bottom: 1px solid #000;
  height: 32mm;
}

.waybillNumParentYuanTong {
  height: 17mm;
  width: 106mm;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
}

.jijianYuanTong {
  height: 18mm;
  width: 74mm;
  display: flex;
  padding: 2mm;
  flex-direction:row;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 14pt;
}
.jijianItemYuanTong {
  display: flex;
  flex-direction:column;
  height: 6mm;
  text-align: left;
  font-size: 8pt;
}

.waybillNumLeft {
  font-size: 16pt;
  width: 30mm;
  height: 16mm;
  line-height: 16mm;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow:ellipsis;
}

.waybillNumYuanTong2 {
  width: 90mm;
  text-align: center;
  height: 16.5mm;
  border-left: 1px solid #000;
  line-height: 16.5mm;
}

.sendReceiveAdInfo {
  display: table-cell;
  height: 30mm;
  width: 98mm;
}
.sendReceiveAdInfoYuanTong {
  display: table-cell;
  width: 110mm;
  /*border-bottom: 1px solid #000;*/
}

.receiveInfoBox {
  border-bottom: 1px solid #000000;
  height: 16mm;
  width: 98mm;
}
.receiveInfoBoxYuanTong {
  border-bottom: 1px solid #000000;
  height: 24mm;
  width: 106mm;
  margin-left: 2mm;
  margin-right: 1mm;
}

.receive {
  width: 5mm;
  height: 18mm;
  font-size: 8pt;
  border-right: 1px solid #000;
  display: table-cell;
  vertical-align: middle;
}
.receiveYuanTong {
  width: 10mm;
  height: 16mm;
  text-align: center;
  font-size: 16pt;
  display: table-cell;
  vertical-align: top;
}

.receiveContent {
  width: 98mm;
  height: 18mm;
  font-size: 8pt;
  padding-left: 1mm;
  display: table-cell;
}
.receiveContentYuanTong {
  width: 96mm;
  height: 24mm;
  font-size: 12pt;
  padding-left: 6mm;
  text-align: left;
  display: table-cell;
}
.receiveContentYuanTong >span:first-child {
  width: 100mm;
  height: 10mm;
  font-size: 10pt;
}
.receiveContentYuanTong >span:last-child {
  width: 100mm;
  height: 12mm;
  font-size: 10pt;
}

.sendInfoBox {
  height: 14mm;
  width: 98mm;
}
.sendInfoBoxYuanTong {
  height: 24mm;
  width: 108mm;
  margin-left: 2mm;
  margin-right: 1mm;
  box-sizing: border-box;
}

.send {
  height: 15mm;
  width: 5mm;
  border-right: 1px solid #000;
  font-size: 8pt;
  display: table-cell;
  vertical-align: middle;
}
.sendYuanTong {
  width: 10mm;
  height: 16mm;
  font-size: 16pt;
  display: table-cell;
  vertical-align: top;
  text-align: center;
}

.sendContent {
  padding-left: 1mm;
  width: 98mm;
  height: 15mm;
  font-size: 6pt;
  display: table-cell;
}
.sendContentYuanTong {
  padding-left: 6mm;
  width: 96mm;
  height: 26mm;
  text-align: left;
  display: table-cell;
}
.sendContentYuanTong >span:first-child {
  width: 100mm;
  height: 10mm;
  font-size: 10pt;
}
.sendContentYuanTong >span:last-child {
  width: 100mm;
  height: 10mm;
  font-size: 10pt;
}

.sendReceiveAdOpera {
  width: 24mm;
  border-left: 1px solid #000;
  display: table-cell;
  vertical-align: middle;
  height: 30mm;
}

.sendReceiveServer {
  width: 24mm;
  height: 5mm;
  border-bottom: 1px solid #000;
  text-align: center;
  font-size: 6px;
}

.serverInfo {
  width: 24mm;
  height: 25mm;
  font-size: 5pt;
}

.serverInfo > p {
  margin: 0;
  padding: 1px;
}

.waybillNum {
  margin-top: 5px;
  height: 26mm;
  font-size: 35px;
  border-bottom: 1px solid #000;
  text-align: center;
}
.waybillNumYuanTong {
  height: 22mm;
  line-height: 22mm;
  width: 100%;
  text-align: center;
  margin-top: 1px;
  font-size: 12px;
}

.waybillNum2 {
  margin-top: 5px;
  height: 26mm;
  text-align: center;
  border-bottom: 1px solid #000;
}
#waybillNumCode {
  max-width: 70mm;
}

.singer {
  border-bottom: 2mm solid #000000;
  height: 19mm;
}

.singerTip {
  display: table-cell;
  width: 44mm;
  border-right: 1px solid #000;
  font-size: 6pt;
}

.singerName {
  font-size: 8pt;
  display: table-cell;
  width: 100mm;
}

.singerName > p {
  padding: 8px;
  margin: 0;
}
.singerName > p > span{
  width: 20mm;
}

.companyCode {
  border-bottom: 1px solid #000000;
  border-top: 2mm solid #000000   ;
  height: 14mm;
  width: 98mm;
  box-sizing: content-box;
}

.companyCode > div {
  display: inline-block;
  padding: 0 1mm;
}

.companyCode > div:nth-child(1) {
  width: 30mm;
  height: 14mm;
}

.companyCode > div:nth-child(2) {
  width: 68mm;
  height: 14mm;
  text-align: right;
  float: right;
}

.companyCodeLogo {
  width: 26mm;
  height: 8mm;
  margin-top: 3mm;
}

.companyCodeImg {
  width: 40mm;
  height: 11mm;
  margin-top: 1.5mm;
}

.receiptBox {
  height: 14mm;
  border-bottom: 1px solid #000;
  font-size: 6pt;
}

.receiptBoxTitle {
  width: 5mm;
  height: 14mm;
  font-size: 8pt;
  border-right: 1px solid #000000;
  display: table-cell;
  vertical-align: middle;
}

.receiptBoxContent {
  display: table-cell;
  width: 98mm;
  height: 14mm;
  padding: 0 2px;
  vertical-align: middle;
}

.customArea {
  height: 40mm;
  font-weight: bold;
  font-size: 8pt;
  padding: 5px;
  box-sizing: border-box;
}

.receiptBoxContent > p {
  margin: 0;
}

 @media print {
   @page {size:210mm 230mm!important;
     margin: 0;
     padding: 0;
   }
   .printBoxYuanTong {
     position: relative;
     z-index: -1;
     width: 100mm;
     /*margin-left: 15mm;*/
     margin: 0;
     padding: 0;
     text-align: center;
     height: 190mm;
     color: #000000;
     font-family: 'Microsoft YaHei',serif;
   }

   .printCompanyYuanTong {
     border-bottom: 1px solid #000000;
     height: 20mm;
     display: flex;
     justify-content: space-between;
     padding: 0 0;
   }

   .companyLogoYuanTong {
     width: 42mm;
     height: 20mm;
     display: flex;
     justify-content: left;
     justify-items: center;
     flex-direction: column;
     padding: 1mm;
   }

   .companyLogoYuanTong > img {
     width: 26mm;
     height: 8mm;
   }

   .companyLogoYuanTong > span{
     padding-left: 2mm;
     margin: 0;
     font-size: 8pt;
   }
   /*.companyLogoYuanTong > span:last-child {*/
   /*  padding: 0;*/
   /*  margin: 0;*/
   /*  font-size: 8pt;*/
   /*}*/

   .trackNumYuanTong {
     font-weight: bold;
     line-height: 10mm;
     height: 10mm;
     margin-right: 10px;
     margin-left: 10px;
     font-size: 22pt;
     text-align: center;
     border-bottom: 0.1mm solid #000000;
   }

   .sendReceiveAdBoxYuanTong {
     border-bottom: 0.1mm solid #000;
     height: 38mm;
   }

   .waybillNumParentYuanTong {
     height: 12mm;
     width: 74mm;
     display: flex;
     text-align: center;
     justify-content: space-between;
     border-bottom: 0.1mm solid #000;
     box-sizing: border-box;
   }

   .jijianYuanTong {
     height: 18mm;
     width: 74mm;
     display: flex;
     padding: 2mm;
     flex-direction:row;
     justify-content: space-between;
     border-bottom: 0.1mm solid #000;
     box-sizing: border-box;
     font-size: 14pt;
   }
   .jijianItemYuanTong {
     display: flex;
     flex-direction:column;
     height: 6mm;
     text-align: left;
     font-size: 8pt;
   }

   .waybillNumYuanTong2 {
     width: 50mm;
     height: 12mm;
   }

   .receiveInfoBoxYuanTong {
     border-bottom: 0.1mm solid #000000;
     height: 24mm;
     width: 72mm;
     padding: 1mm 1mm;

   }

   .receiveContentYuanTong {
     width: 100mm;
     height: 24mm;
     font-size: 10pt;
     padding-left: 1mm;
     display: table-cell;
   }
   .receiveContentYuanTong >span:first-child {
     width: 100mm;
     height: 10mm;
     font-size: 10pt;
   }
   .receiveContentYuanTong >span:last-child {
     width: 100mm;
     height: 12mm;
     font-size: 10pt;
   }

   .sendInfoBoxYuanTong {
     height: 14mm;
     width: 100mm;
     padding: 1mm 1mm;
     box-sizing: border-box;
   }

   .sendContentYuanTong {
     padding-left: 1mm;
     width: 100mm;
     height: 8mm;
     display: table-cell;
   }
   .sendContentYuanTong >span:first-child {
     width: 100mm;
     height: 7mm;
     font-size: 8pt;
   }
   .sendContentYuanTong >span:last-child {
     width: 100mm;
     height: 7mm;
     font-size: 8pt;
   }
 }

</style>
